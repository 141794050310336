<template>
  <div>
    <b-tabs content-class="mt-3">
      <b-tab
        title="Dados gerais"
        active
        @click="hideModal('DocumentsConditioningRelations')"
      >
        <LicenseTypeDetailsGeneralData />
      </b-tab>
      <div v-if="!verifyRouteAddNewItem()">
        <b-tab>
          <template #title>
            Documentos
            <b-badge
              variant="primary"
              pill
              class="mr-1"
            >
              {{ documents.length }}
            </b-badge>
          </template>
          <LicenseTypeDetailsConditioningDocuments
            :items="documents"
            :documents="true"
          />
        </b-tab>
        <b-tab @click="hideModal('DocumentsConditioningRelations')">
          <template #title>
            Condicionantes
            <b-badge
              variant="primary"
              pill
              class="mr-1"
            >
              {{ conditioning.length }}
            </b-badge>
          </template>
          <LicenseTypeDetailsConditioningDocuments
            :items="conditioning"
            :conditioning="true"
          />
        </b-tab>
      </div>
    </b-tabs>
  </div>
</template>

<script>
import { BTabs, BTab, BBadge } from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import LicenseTypeDetailsGeneralData from './LicenseTypeDetailsGeneralData.vue'
import LicenseTypeDetailsConditioningDocuments from '@/components/documents-conditioning/DocumentsConditioning.vue'

export default {
  components: {
    BTabs,
    BTab,
    BBadge,

    LicenseTypeDetailsGeneralData,
    LicenseTypeDetailsConditioningDocuments,
  },
  computed: {
    ...mapGetters({
      getDocumentsCondintioning: 'licenseType/getDocumentsCondintioning',
      getDocuments: 'licenseType/getDocuments',
      getConditioning: 'licenseType/getConditioning',
    }),
    documents() {
      return this.getDocuments.length > 0 ? this.getDocuments : []
    },
    conditioning() {
      return this.getConditioning.length > 0 ? this.getConditioning : []
    },
  },
  created() {
    if (!this.verifyRouteAddNewItem()) {
      this.fetchDocumentsCondintioningByLicenseTypeId(this.$route.params.id)
    }
  },
  methods: {
    ...mapActions({ fetchDocumentsCondintioningByLicenseTypeId: 'licenseType/fetchDocumentsCondintioningByLicenseTypeId' }),
  },
}
</script>
