<template>
  <div>
    <div
      v-if="$can('update', 'licensetype')"
      class="btn-actions-content d-flex"
    >
      <b-button
        v-if="edit"
        class="d-flex align-items-center mr-3 button-cancel"
        variant="outline-primary"
        @click="cancel()"
      >
        <VSIcons
          name="CloseSquare"
          width="22"
          height="22"
          class="pr-2"
        />
        <span>Cancelar</span>
      </b-button>
      <b-button
        v-if="!edit"
        class="btn-grad d-flex align-items-center"
        @click="setEdit(true)"
      >
        <VSIcons
          name="Edit"
          width="22"
          height="22"
          class="pr-2"
        />
        <span>Editar</span>
      </b-button>
      <b-button
        v-else
        class="btn-grad d-flex align-items-center"
        @click="saveChanges(licenseTypeData)"
      >
        <VSIcons
          name="TickSquare"
          width="22"
          height="22"
          class="pr-2"
        />
        <span>Salvar</span>
      </b-button>
    </div>
    <div v-if="!apiInCall">
      <validation-observer ref="form">
        <h6>Dados do tipo de licença</h6>
        <b-form class="mt-3">
          <b-row>
            <b-col md="3">
              <validation-provider
                v-slot="{ errors }"
                name="título"
                rules="required"
              >
                <b-form-group label="Título">
                  <b-input-group>
                    <b-form-input
                      id="titulo"
                      v-model="licenseTypeData.subject"
                      placeholder="Título"
                      type="text"
                      :disabled="!edit"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="1">
              <validation-provider
                v-slot="{ errors }"
                name="sigla"
                rules="required"
              >
                <b-form-group label="Sigla">
                  <b-input-group>
                    <b-form-input
                      id="sigla"
                      v-model="licenseTypeData.acronyms"
                      placeholder="Sigla"
                      type="text"
                      :disabled="!edit"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                v-slot="{ errors }"
                name="descrição"
                rules="required"
              >
                <b-form-group label="Descrição">
                  <b-input-group>
                    <b-form-input
                      id="descricao"
                      v-model="licenseTypeData.description"
                      placeholder="Descrição"
                      type="text"
                      :disabled="!edit"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="2">
              <validation-provider
                v-slot="{ errors }"
                name="órgão licenciador"
                rules="required"
              >
                <b-form-group label="Órgão licenciador">
                  <b-input-group>
                    <b-form-select
                      id="orgao-licenciador"
                      v-model="licenseTypeData.licensing_agency.id"
                      :options="licensingAgenciesOtions"
                      :disabled="!edit"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </div>
    <b-spinner
      v-else
      variant="primary"
      class="general-data-spinner"
    />
  </div>
</template>

<script>
import {
  BForm, BInputGroup, BFormInput, BFormGroup, BRow, BCol, BButton, BSpinner, BFormSelect,
} from 'bootstrap-vue'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import VSIcons from '@/assets/icons/VSIcons.vue'
import licenseType from '@/services/licenseType'
import { required } from '@/@core/utils/validations/validations'

export default {
  components: {
    BForm, BInputGroup, BFormInput, BFormGroup, BRow, BCol, BButton, VSIcons, BSpinner, BFormSelect,
  },
  data() {
    return {
      required,
      createLicenseType: false,
      licenseTypeData: {
        subject: '',
        acronyms: '',
        description: '',
        licensing_agency: { name: '' },
      },
    }
  },
  computed: {
    ...mapGetters({
      edit: 'app/getItemTableEdit', apiInCall: 'app/getApiInCall', licensingAgenciesOtions: 'publicAgencies/getLicensingAgencyOptions',
    }),
  },
  created() {
    if (this.verifyRouteAddNewItem()) {
      this.createLicenseType = true
      this.setSubtitle('')
    } else {
      this.fetchLicenseType(this.$route.params.id)
    }
    this.fetchLicensingAgencies()
  },
  methods: {
    ...mapMutations({ setEdit: 'app/SET_ITEM_TABLE_EDIT', setSubtitle: 'app/SET_SUBTITLE', setLicenseTypeData: 'licenseType/SET_LICENSE_TYPE_DATA' }),
    ...mapActions({ fetchSectors: 'sector/fetchSectors', fetchLicensingAgencies: 'publicAgencies/fetchLicensingAgencies', fetchDocumentsCondintioningByLicenseTypeId: 'licenseType/fetchDocumentsCondintioningByLicenseTypeId' }),
    fetchLicenseType(id) {
      licenseType.getLicenseTypeById(id).then(res => {
        this.licenseTypeData = res.data
        this.setLicenseTypeData(res.data)
        this.setSubtitle(this.licenseTypeData.subject)
      })
    },
    saveChanges(form) {
      this.$refs.form.validate().then(success => {
        if (success) {
          if (this.createLicenseType) {
            licenseType.createLicenseType(form).then(res => {
              this.setEdit(false)
              this.setSubtitle(this.licenseTypeData.subject)
              this.fetchDocumentsCondintioningByLicenseTypeId(res.data.id)
              this.$router.push(`/tipos-licenca/${res.data.id}/detalhes`)
              this.toast({
                title: 'Criado!',
                text: 'Tipo de licença criada com sucesso!',
                variant: 'success',
              })
            })
          } else {
            licenseType.editLicenseType(form).then(() => {
              this.setEdit(false)
              this.setSubtitle(this.licenseTypeData.subject)
              this.toast({
                title: 'Atualizado!',
                text: 'Tipo de licença atualizada com sucesso!',
                variant: 'success',
              })
            })
          }
        }
      })
    },

    cancel() {
      this.setEdit(false)
      if (this.createLicenseType) {
        this.$router.push({ name: 'license-type' })
      } else {
        this.fetchLicenseType(this.$route.params.id)
      }
    },

    getAddress(cep) {
      this.$refs.cep.validate().then(success => {
        if (success) {
          const address = this.getAdressByCep(cep)
          this.companyData.address = address.logradouro
          this.companyData.neighborhood = address.bairro
          this.companyData.state = address.uf
          this.companyData.city = address.localidade
          this.companyData.address_number = address.complemento
        }
      })
    },
  },
}
</script>
