<template>
  <LicenseTypeDetails />
</template>

<script>
import LicenseTypeDetails from '@/modules/license-type/license-type-details/LicenseTypeDetails.vue'

export default {
  components: { LicenseTypeDetails },
}
</script>
