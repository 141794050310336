var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.$can('update', 'licensetype'))?_c('div',{staticClass:"btn-actions-content d-flex"},[(_vm.edit)?_c('b-button',{staticClass:"d-flex align-items-center mr-3 button-cancel",attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.cancel()}}},[_c('VSIcons',{staticClass:"pr-2",attrs:{"name":"CloseSquare","width":"22","height":"22"}}),_c('span',[_vm._v("Cancelar")])],1):_vm._e(),(!_vm.edit)?_c('b-button',{staticClass:"btn-grad d-flex align-items-center",on:{"click":function($event){return _vm.setEdit(true)}}},[_c('VSIcons',{staticClass:"pr-2",attrs:{"name":"Edit","width":"22","height":"22"}}),_c('span',[_vm._v("Editar")])],1):_c('b-button',{staticClass:"btn-grad d-flex align-items-center",on:{"click":function($event){return _vm.saveChanges(_vm.licenseTypeData)}}},[_c('VSIcons',{staticClass:"pr-2",attrs:{"name":"TickSquare","width":"22","height":"22"}}),_c('span',[_vm._v("Salvar")])],1)],1):_vm._e(),(!_vm.apiInCall)?_c('div',[_c('validation-observer',{ref:"form"},[_c('h6',[_vm._v("Dados do tipo de licença")]),_c('b-form',{staticClass:"mt-3"},[_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"título","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Título"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"id":"titulo","placeholder":"Título","type":"text","disabled":!_vm.edit},model:{value:(_vm.licenseTypeData.subject),callback:function ($$v) {_vm.$set(_vm.licenseTypeData, "subject", $$v)},expression:"licenseTypeData.subject"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,3574741345)})],1),_c('b-col',{attrs:{"md":"1"}},[_c('validation-provider',{attrs:{"name":"sigla","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Sigla"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"id":"sigla","placeholder":"Sigla","type":"text","disabled":!_vm.edit},model:{value:(_vm.licenseTypeData.acronyms),callback:function ($$v) {_vm.$set(_vm.licenseTypeData, "acronyms", $$v)},expression:"licenseTypeData.acronyms"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,1724515204)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"descrição","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Descrição"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"id":"descricao","placeholder":"Descrição","type":"text","disabled":!_vm.edit},model:{value:(_vm.licenseTypeData.description),callback:function ($$v) {_vm.$set(_vm.licenseTypeData, "description", $$v)},expression:"licenseTypeData.description"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,1382021731)})],1),_c('b-col',{attrs:{"md":"2"}},[_c('validation-provider',{attrs:{"name":"órgão licenciador","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Órgão licenciador"}},[_c('b-input-group',[_c('b-form-select',{attrs:{"id":"orgao-licenciador","options":_vm.licensingAgenciesOtions,"disabled":!_vm.edit},model:{value:(_vm.licenseTypeData.licensing_agency.id),callback:function ($$v) {_vm.$set(_vm.licenseTypeData.licensing_agency, "id", $$v)},expression:"licenseTypeData.licensing_agency.id"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,1771925557)})],1)],1)],1)],1)],1):_c('b-spinner',{staticClass:"general-data-spinner",attrs:{"variant":"primary"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }